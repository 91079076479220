import React from 'react'

const Modal = ({ content, id }) => {

    return (
        <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header d-flex justify-content-end p-4 pb-2">
                        <button type="button" className="btn-none" data-bs-dismiss="modal" aria-label="Close">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.2095 1.70711C18.6001 1.31658 18.6001 0.683417 18.2095 0.292893C17.819 -0.0976311 17.1859 -0.0976311 16.7953 0.292893L9.50122 7.587L2.20711 0.292893C1.81658 -0.0976311 1.18342 -0.0976311 0.792893 0.292893C0.402369 0.683417 0.402369 1.31658 0.792893 1.70711L8.087 9.00122L0.792893 16.2953C0.402369 16.6859 0.402369 17.319 0.792893 17.7095C1.18342 18.1001 1.81658 18.1001 2.20711 17.7095L9.50122 10.4154L16.7953 17.7095C17.1859 18.1001 17.819 18.1001 18.2095 17.7095C18.6001 17.319 18.6001 16.6859 18.2095 16.2953L10.9154 9.00122L18.2095 1.70711Z" fill="#8F8E8E" />
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body fs-14-400">
                        <h1 className="modal-title text-center fs-24-bold mb-3" id={`${id}Label`}>
                            {id === "tyc" ?
                                "Términos y condiciones" : "Establecimientos adheridos"
                            }
                        </h1>
                        {content}
                    </div>
                    <div className="mt-auto d-grid gap-2 p-4">
                        <button type="button" className="btn cerrar-modal fs-16-bold" data-bs-dismiss="modal">Aceptar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;