import Lottie from 'react-lottie';
import React, { useEffect, useRef, useState } from 'react';


const Cargando = () => {

    const [LottieLogoAxion, setLottieLogoAxion] = useState();

    let isMounted = useRef(true);

    useEffect(() => {

        if (isMounted.current) {
            import('../animaciones/logo-axion.json').then(setLottieLogoAxion);
        }

        return () => {
            isMounted.current = false
        }
    }, []);

    const logoAxion = {
        loop: true,
        autoplay: true,
        animationData: LottieLogoAxion,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <div className="d-flex flex-column min-vh-100" style={{
            background: 'linear-gradient(180deg, #0C1561 0%, #075E92 100%)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }
        }>
            <div className="my-auto">
                <div className="d-flex flex-row justify-content-evenly align-items-center flex-wrap col-12 col-lg-12 mx-auto mb-75">
                    <div className="d-flex flex-column text-center align-items-center">
                        <div>
                            <Lottie
                                options={logoAxion}
                                height={220}
                                width={300}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cargando;