import React from 'react'

function BackgroundHeader(props) {
    return (
        <div className="d-flex flex-column min-vh-100 vh-100 w-100"
            style={{
                backgroundColor: '#0c1561',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }
            }>
            <img height="30px" width="41px" className="my-3 mx-auto" src={`${window.location.origin}/img/logo-header.svg`} alt="Logo de ON"></img>
            {props.children}
        </div>
    )
}

export default BackgroundHeader
