import { UnauthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { b2cPolicies, loginRequest, tokenRequest } from '../../config/authConfig'
import { EventType, InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import Lottie from 'react-lottie';
import HomeScreen from '../layout/HomeScreen'
import Carousel from 'react-bootstrap/Carousel';

const Onboarding = () => {


    const [showHomeScreen, setShowHomeScreen] = useState(true);
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAuthenticated = useIsAuthenticated();

    const [Estacion, setEstacion] = useState();
    const [Podio, setPodio] = useState();
    const [Celular, setCelular] = useState();
    const [Velocimetro, setVelocimetro] = useState();
    const [index, setIndex] = useState(0);

    const location = useLocation();
    const navigate = useNavigate();

    let isMounted = useRef(true);
    
    useEffect(() => {
        document.cookie = "msal.interaction.status=; expires=Thu, 01 Jan 1970 00:00:02 GMT;";

        if (isMounted.current) {
            import('../animaciones/estacion.json').then(setEstacion);
            import('../animaciones/podio.json').then(setPodio);
            import('../animaciones/celular.json').then(setCelular);
            import('../animaciones/velocimetro.json').then(setVelocimetro);
        }

        setTimeout(function () {
            setShowHomeScreen(false)
        }, 2800);

        // localStorage.clear();
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        //alert('Complete Cache Cleared')

        const callbackId = instance.addEventCallback((event) => {
            
            if (event.eventType === EventType.LOGIN_FAILURE) {
                
                if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
                    if (event.interactionType === InteractionType.Redirect) {
                        instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
                    } else if (event.interactionType === InteractionType.Popup) {
                        instance.loginPopup(b2cPolicies.authorities.forgotPassword)
                            .catch(e => {
                                return;
                            });
                    }
                }
            }

            if (event.eventType === EventType.LOGIN_SUCCESS) {
                // console.log("4 - login SUCCESS");
                if (event?.payload) {
                    /**
                     * We need to reject id tokens that were not issued with the default sign-in policy.
                     * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
                     * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                     */
                    if (b2cPolicies.names.forgotPassword.includes(event.payload.idTokenClaims["tfp"])) {
                        // console.log("4B - login SUCCESS");
                        // window.alert("La contraseña ha sido actualizada. \nPor favor ingrese con su nueva contraseña");
                        // return instance.logout();
                        const account = instance.getActiveAccount(); 
                        instance.logoutRedirect({ 
                            account, postLogoutRedirectUri: '/', 
                            onRedirectNavigate:()=>{ return true; } 
                        }); 
                        // return window.location.replace("/");
                    }
                }
            }
            else{
                if(event?.eventType!="msal:acquireTokenStart" && event?.eventType!="msal:handleRedirectEnd" && event?.eventType!="msal:acquireTokenSuccess"){
                    clear_fake_data_b2c();
                }
            }
        })

        if (inProgress === InteractionStatus.None && !isAuthenticated && location.pathname !== '/') {
            if (accounts.length === 0) {
                navigate("/");
            }
        }

        if (account && inProgress === InteractionStatus.None) {
            // console.log("7 - obteniendo token");
            instance.acquireTokenSilent({
                scopes: tokenRequest.scopes,
                account: account
            }).then((response) => {

                const { accessToken } = response;

                localStorage.removeItem('token');
                localStorage.setItem('token', accessToken);

                window.location.replace("/home")

            }).catch((error) => {
                // in case if silent token acquisition fails, fallback to an interactive method
                if (error instanceof InteractionRequiredAuthError) {

                    if (account && inProgress === "none") {
                        instance.acquireTokenRedirect({
                            scopes: tokenRequest.scopes,
                        }).then((response) => {

                        }).catch(error => console.log(error));
                    }
                }
            });
        }


        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
            isMounted.current = false
        };

    }, [instance, accounts, inProgress])

    const clear_fake_data_b2c = async () => {
         for(let i=0; i<localStorage.length; i++) {
             let key = localStorage.key(i);
             var stored =localStorage.getItem(key);
            //  if(key.includes("passwordreset")){
            if(key.includes("passwordreset") || key.includes("signin")){
                 localStorage.removeItem(key);
            }
         }
        if (sessionStorage.getItem("msal.interaction.status")){
             sessionStorage.removeItem("msal.interaction.status");
        }
        
        // alert('Cookies guardadas por este sitio web:\n\n' + document.cookie.replace(/; /g,'\n'));
        ////////////////////////////////////////////////
        ////////////////////////////////////////////////
        // document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        // var cookies = document.cookie.split("; ");
        // for (var c = 0; c < cookies.length; c++) {
        //     var d = window.location.hostname.split(".");
        //     while (d.length > 0) {
        //         var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        //         var p = location.pathname.split('/');
        //         document.cookie = cookieBase + '/';
        //         while (p.length > 0) {
        //             document.cookie = cookieBase + p.join('/');
        //             p.pop();
        //         };
        //         d.shift();
        //     }
        // }        
        ////////////////////////////////////////////////
        ////////////////////////////////////////////////
    }
    
    // Lorries Configurations
    
    const estacion = {
        loop: true,
        autoplay: true,
        animationData: Estacion,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    const podio = {
        loop: true,
        autoplay: true,
        animationData: Podio,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const celular = {
        loop: true,
        autoplay: true,
        animationData: Celular,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const velocimetro = {
        loop: true,
        autoplay: true,
        animationData: Velocimetro,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    if (showHomeScreen) {
        return <HomeScreen />
    }

    const carrouselItems = [estacion, podio, celular, velocimetro]

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <UnauthenticatedTemplate>
            <div className="d-flex flex-column min-vh-100 w-100"
                style={{
                    background: `url(${window.location.origin}/img/gradiente.webp)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                <div className="d-flex flex-column bg-white-rounded justify-content-center flex-fill align-self-center">
                    <div className="my-auto mx-auto">
                        <img
                            src={`${window.location.origin}/img/logo-axion.svg`}
                            className="d-block w-100"
                            alt="Logo de AXION Energy" />
                    </div>
                    <div className="my-auto px-2">
                        <Carousel className='text-center' activeIndex={index} onSelect={handleSelect}>
                            {carrouselItems.length > 0 && carrouselItems.map((item, i) => (
                                <Carousel.Item key={i} className='mb-5' interval={8000}>
                                    <Lottie
                                        options={item}
                                        height={220}
                                        width={300}
                                    />
                                    <div className="mt-3">
                                        <p className="fs-18-700">Subite a ON.</p>
                                        <p className="fs-18">El programa de descuentos y beneficios de AXION</p>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    <div className="mt-auto d-grid gap-2">
                        <button onClick={() => instance.loginRedirect(loginRequest)} className="btn btn-azul fs-16-bold">Iniciar Sesión</button>
                        <Link
                            to={"/crear-cuenta/1"}
                            onClick={() => {
                                window.gtag('event', 'sign_up', {
                                    'page_location': window.location.href,
                                    'event_category': 'registro_1',
                                    'value': '10'
                                })
                                window.dataLayer.push({
                                    "event": "click_crearCuenta",
                                    "category": "Onboarding",
                                    "value": index
                                });
                            }}
                            className="btn text-fucsia fs-16-bold">Crear cuenta</Link>
                    </div>
                </div>
            </div>
        </UnauthenticatedTemplate>
    )
}

export default Onboarding