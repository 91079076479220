import Lottie from 'react-lottie';
import React from 'react';
import * as LogoAxion from '../animaciones/cargandoLogoAxion.json'


const Cargando = () => {

    const cargando = {
        loop: true,
        autoplay: true,
        animationData: LogoAxion,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="my-auto">
                <div className="d-flex flex-row justify-content-evenly align-items-center flex-wrap col-12 col-lg-12 mx-auto mb-75">
                    <div className="d-flex flex-column text-center align-items-center">
                        <div>
                            <Lottie
                                options={cargando}
                                height={130}
                                width={130}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cargando;