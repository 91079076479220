import React, { Fragment, useEffect, useContext, useState, useRef } from 'react';
import CheckboxesFiltros from './CheckboxesFiltros';
import beneficiosContext from '../../context/beneficios/beneficiosContext';
import usuarioContext from '../../context/usuario/usuarioContext';
import Swal from 'sweetalert2'


const BeneficiosFiltros = (props) => {
    const { buscarBeneficiosFiltrados, beneficiosMarcas, beneficiosDescuentos, beneficiosCategorias, beneficiosMonedas } = useContext(beneficiosContext);
    const { contacto } = useContext(usuarioContext);
    let isMounted = useRef(true);

    const initialValue = {
        verTodos: false,
        masCercanos: {},
        marcas: [],
        descuentos: [],
        categorias: [],
        monedas: []
    }

    const [formData, setFormData] = useState(initialValue);

    function shut_down_filters(e) {
        e.preventDefault();
        document.querySelector('.screen-darken-child').classList.remove('active');
        props.setMostrarFiltros(false);
    }

    function darken_screen2(yesno) {
        if (yesno === true) {
            document.querySelector('.screen-darken-child').classList.add('active');
        } else if (yesno === false) {
            document.querySelector('.screen-darken-child').classList.remove('active');
        }
    }

    const ref = useRef(null)

    useEffect(() => {
        const handler = () => {
            props.setMostrarFiltros(false);
            darken_screen2(false);
        }

        ref.current = handler
        document.querySelector('.screen-darken-child')?.addEventListener('click', ref.current);
        
    },[])


    const enable_filters = (nombreFiltro) => {
        switch (nombreFiltro) {
            case "Ver todos":
                if (props.valoresFiltros.verTodos == false) {
                    props.setValoresFiltros({
                        ...props.valoresFiltros,
                        verTodos: !props.valoresFiltros.verTodos,
                        porMarca: false,
                        porCategoria: false,
                        porDescuento: false,
                        porMoneda: false
                    });

                    props.checkboxLists.beneficiosMarcas.map((elem, index) => (
                        elem.checked = false
                    ));
                    props.checkboxLists.beneficiosDescuentos.map((elem, index) => (
                        elem.checked = false
                    ));
                    props.checkboxLists.beneficiosCategorias.map((elem, index) => (
                        elem.checked = false
                    ));
                    props.checkboxLists.beneficiosMonedas.map((elem, index) => (
                        elem.checked = false
                    ));

                    props.expandirFiltros.filtroMarca = false;
                    props.expandirFiltros.filtroDescuento = false;
                    props.expandirFiltros.filtroCategoria = false;
                    props.expandirFiltros.filtroMoneda = false;
                }

                break;

            case "Mas cercanos":
                if (props.valoresFiltros.masCercanos == false) {
                    navigator.geolocation.getCurrentPosition((data) => {
                        props.setValoresFiltros({
                            ...props.valoresFiltros,
                            masCercanos: true
                        });
                    },
                        (err) => {
                            props.setValoresFiltros({
                                ...props.valoresFiltros,
                                masCercanos: false
                            });
                            Swal.fire({
                                html: `
                                <p class="fs-24-bold mt-4">Hay increíbles beneficios cerca tuyo</p>
                                <img class="my-3" src=${window.location.origin}/img/geolocalizacion.svg width="175px" alt="Imágen de geolocalización" />
                                <p class="fs-14-400">Debes activar tu ubicación en tu dispositivo móvil para encontrar beneficios cercanos</p>
                                `,
                                confirmButtonText: 'Aceptar',
                                showCloseButton: true,
                                closeButtonHtml: `
                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.2095 1.70711C18.6001 1.31658 18.6001 0.683417 18.2095 0.292893C17.819 -0.0976311 17.1859 -0.0976311 16.7953 0.292893L9.50122 7.587L2.20711 0.292893C1.81658 -0.0976311 1.18342 -0.0976311 0.792893 0.292893C0.402369 0.683417 0.402369 1.31658 0.792893 1.70711L8.087 9.00122L0.792893 16.2953C0.402369 16.6859 0.402369 17.319 0.792893 17.7095C1.18342 18.1001 1.81658 18.1001 2.20711 17.7095L9.50122 10.4154L16.7953 17.7095C17.1859 18.1001 17.819 18.1001 18.2095 17.7095C18.6001 17.319 18.6001 16.6859 18.2095 16.2953L10.9154 9.00122L18.2095 1.70711Z" fill="#8F8E8E" />
                                </svg>
                                `
                            })
                        });
                }
                else {
                    props.setValoresFiltros({
                        ...props.valoresFiltros,
                        masCercanos: !props.valoresFiltros.masCercanos
                    });
                }
                break;
            case "Por marca":
                props.setExpandirFiltros({
                    ...props.expandirFiltros,
                    filtroMarca: !props.expandirFiltros.filtroMarca,
                });
                props.setValoresFiltros({
                    ...props.valoresFiltros,
                    verTodosVer: !props.valoresFiltros.verTodosVer,
                    masCercanosVer: !props.valoresFiltros.masCercanosVer,
                    porCategoriaVer: !props.valoresFiltros.porCategoriaVer,
                    porDescuentoVer: !props.valoresFiltros.porDescuentoVer,
                    porMonedaVer: !props.valoresFiltros.porMonedaVer
                });

                break;
            case "Por categoria":
                props.setExpandirFiltros({
                    ...props.expandirFiltros,
                    filtroCategoria: !props.expandirFiltros.filtroCategoria,
                    verTodos: false,
                });
                props.setValoresFiltros({
                    ...props.valoresFiltros,
                    verTodosVer: !props.valoresFiltros.verTodosVer,
                    masCercanosVer: !props.valoresFiltros.masCercanosVer,
                    porMarcaVer: !props.valoresFiltros.porMarcaVer,
                    porDescuentoVer: !props.valoresFiltros.porDescuentoVer,
                    porMonedaVer: !props.valoresFiltros.porMonedaVer
                });

                break;
            case "Por descuento":
                props.setExpandirFiltros({
                    ...props.expandirFiltros,
                    filtroDescuento: !props.expandirFiltros.filtroDescuento,
                });
                props.setValoresFiltros({
                    ...props.valoresFiltros,
                    verTodosVer: !props.valoresFiltros.verTodosVer,
                    masCercanosVer: !props.valoresFiltros.masCercanosVer,
                    porMarcaVer: !props.valoresFiltros.porMarcaVer,
                    porCategoriaVer: !props.valoresFiltros.porCategoriaVer,
                    porMonedaVer: !props.valoresFiltros.porMonedaVer
                });
                break;
            case "Por moneda":
                props.setExpandirFiltros({
                    ...props.expandirFiltros,
                    filtroMoneda: !props.expandirFiltros.filtroMoneda,
                });
                props.setValoresFiltros({
                    ...props.valoresFiltros,
                    verTodosVer: !props.valoresFiltros.verTodosVer,
                    masCercanosVer: !props.valoresFiltros.masCercanosVer,
                    porMarcaVer: !props.valoresFiltros.porMarcaVer,
                    porCategoriaVer: !props.valoresFiltros.porCategoriaVer,
                    porDescuentoVer: !props.valoresFiltros.porDescuentoVer
                });
                break;
            default:
                break;
        }
    }

    const quitarFiltros = () => {

        let data = {
            verTodos: false,
            masCercanos: {},
            marcas: [],
            descuentos: [],
            categorias: [],
            monedas: []
        }

        data.masCercanos = props.valoresFiltros.masCercanos

        props.checkboxLists.beneficiosMarcas.forEach((elem, index) => {
            if (elem.checked == true) {
                data.marcas.push({ idMarca: elem.idMarca, nombre: elem.nombreMarca })
            }
        });
        props.checkboxLists.beneficiosDescuentos.forEach((elem, index) => {
            if (elem.checked == true) {
                data.descuentos.push({ idDescuento: elem.idDescuento, nombre: elem.cantidadDescuento })
            }
        });
        props.checkboxLists.beneficiosCategorias.forEach((elem, index) => {
            if (elem.checked == true) {
                data.categorias.push({ idCategoria: elem.idCategoria, nombre: elem.categoria })
            }
        });
        props.checkboxLists.beneficiosMonedas.forEach((elem, index) => {
            if (elem.checked == true) {
                data.monedas.push({ nombre: elem.cantidadMonedas })
            }
        });

        data.verTodos = props.valoresFiltros.verTodos;

        sendDataLayer(data, "click_limpiar_filtro");
        
        props.setCheckboxLists({
            ...props.checkboxLists,
            beneficiosMarcas: beneficiosMarcas,
            beneficiosCategorias: beneficiosCategorias,
            beneficiosDescuentos: beneficiosDescuentos,
            beneficiosMonedas: beneficiosMonedas
        })
        props.checkboxLists.beneficiosMarcas.map((elem, index) => {
            if (elem.checked == true) elem.checked = false;
        })
        props.checkboxLists.beneficiosCategorias.map((elem, index) => {
            if (elem.checked == true) elem.checked = false;
        })
        props.checkboxLists.beneficiosDescuentos.map((elem, index) => {
            if (elem.checked == true) elem.checked = false;
        })
        props.checkboxLists.beneficiosMonedas.map((elem, index) => {
            if (elem.checked == true) elem.checked = false;
        })

        props.setValoresFiltros({
            ...props.valoresFiltros,
            verTodos: true,
            porMarca: false,
            porCategoria: false,
            porDescuento: false,
            porMoneda: false
        });

        props.setExpandirFiltros({
            filtroMarca: false,
            filtroCategoria: false,
            filtroDescuento: false,
            filtroMoneda: false
        })
    }

    const disable_filters = (nombreFiltro) => {
        switch (nombreFiltro) {
            case "Ver todos":
                props.setValoresFiltros({
                    ...props.valoresFiltros,
                    verTodos: !props.valoresFiltros.verTodos
                });
                break;
            case "Mas cercanos":
                props.setValoresFiltros({
                    ...props.valoresFiltros,
                    masCercanos: !props.valoresFiltros.masCercanos
                });
                break;
            case "Por marca":
                props.setValoresFiltros({
                    ...props.valoresFiltros,
                    porMarca: !props.valoresFiltros.porMarca
                });
                beneficiosMarcas.forEach((elem, index) => {
                    elem.checked = false;
                })
                break;
            case "Por categoria":
                props.setValoresFiltros({
                    ...props.valoresFiltros,
                    porCategoria: !props.valoresFiltros.porCategoria
                });
                beneficiosCategorias.forEach((elem, index) => {
                    elem.checked = false;
                })
                break;
            case "Por descuento":
                props.setValoresFiltros({
                    ...props.valoresFiltros,
                    porDescuento: !props.valoresFiltros.porDescuento,
                });
                beneficiosDescuentos.forEach((elem, index) => {
                    elem.checked = false;
                })
                break;
            case "Por moneda":
                props.setValoresFiltros({
                    ...props.valoresFiltros,
                    porMoneda: !props.valoresFiltros.porMoneda,
                });
                beneficiosMonedas.forEach((elem, index) => {
                    elem.checked = false;
                })
                break;
            default:
                break;
        }
    }

    const buscarBeneficiosFiltrado = (e) => {
        e.preventDefault();

        props.checkboxLists.beneficiosMarcas.forEach((elem, index) => {
            if (elem.checked == true) {
                formData.marcas.push({ idMarca: elem.idMarca, nombre: elem.nombreMarca })
            }
        });
        props.checkboxLists.beneficiosDescuentos.forEach((elem, index) => {
            if (elem.checked == true) {
                formData.descuentos.push({ idDescuento: elem.idDescuento, nombre: elem.cantidadDescuento })
            }
        });
        props.checkboxLists.beneficiosCategorias.forEach((elem, index) => {
            if (elem.checked == true) {
                formData.categorias.push({ idCategoria: elem.idCategoria, nombre: elem.categoria })
            }
        });
        props.checkboxLists.beneficiosMonedas.forEach((elem, index) => {
            if (elem.checked == true) {
                formData.monedas.push({ nombre: elem.cantidadMonedas })
            }
        });

        formData.verTodos = props.valoresFiltros.verTodos;

        const data = {
            ...formData,
            "masCercanos": props.valoresFiltros.masCercanos
        }

        sendDataLayer(data, "click_aplicar_filtro");

        if (props.valoresFiltros.masCercanos) {

            if (isMounted.current) {
                navigator.geolocation.getCurrentPosition(position => {
                    buscarBeneficiosFiltrados(formData, position.coords.latitude, position.coords.longitude);
                }, function (e) {
                    buscarBeneficiosFiltrados(formData, 0, 0);
                }, {
                    enableHighAccuracy: true
                })
            }
        }
        else {
            buscarBeneficiosFiltrados(formData, 0, 0);
        }

        setFormData(initialValue);
        if (props.buscar) props.setBuscar("");
        props.setMostrarFiltros(false);
        props.backToMenu();
        shut_down_filters(e);
    }

    const sendDataLayer = (data, event) => {
        let filtros = '';

        filtros += data.masCercanos ? 'Más cercanos, ' : '';

        if (data.verTodos || (data.marcas.length === 0 && data.descuentos.length === 0 && data.categorias.length === 0 && data.monedas.length === 0)) {
            filtros += 'Todos'
        } else {
            if (data.marcas.length > 0) filtros += 'Marca/ '
            for (let key in data.marcas) {
                filtros += data.marcas[key].nombre + (parseInt(key) === (data.marcas.length - 1) ? '' : ', ')
            }

            if (data.descuentos.length > 0) filtros += (filtros === '' ? '' : ', ') + 'Descuento/ '
            for (let key in data.descuentos) {
                filtros += data.descuentos[key].nombre + (parseInt(key) === (data.descuentos.length - 1) ? '' : ', ')
            }

            if (data.categorias.length > 0) filtros += (filtros === '' ? '' : ', ') + 'Categoría/ '
            for (let key in data.categorias) {
                filtros += data.categorias[key].nombre + (parseInt(key) === (data.categorias.length - 1) ? '' : ', ')
            }

            if (data.monedas.length > 0) filtros += (filtros === '' ? '' : ', ') + 'Monedas/ '
            for (let key in data.monedas) {
                filtros += data.monedas[key].nombre + (parseInt(key) === (data.monedas.length - 1) ? '' : ', ')
            }
        }

        window.dataLayer.push({
            "event": event,
            "category": filtros
        });

    }

    return (
        <Fragment>
            <span className="screen-darken-child" />
            <form id="navbar_filters" onSubmit={(e) => { buscarBeneficiosFiltrado(e) }} className={`p-3 d-flex flex-column justify-content align-items beneficios-filters-menu ${props.mostrarFiltros ? 'open' : ''}`}>
                <div style={{marginTop: "0.75rem" }}>
                    {props.valoresFiltros.porMarcaVer && props.valoresFiltros.porCategoriaVer && props.valoresFiltros.porDescuentoVer && props.valoresFiltros.porMonedaVer &&
                        <button className='btn-none d-flex justify-content-start align-items-center flex-row' onClick={(e) => shut_down_filters(e)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289C12.0976 3.68342 12.0976 4.31658 11.7071 4.70711L5.41421 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H5.41421L11.7071 19.2929C12.0976 19.6834 12.0976 20.3166 11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L10.2929 3.29289Z" fill="#0C1561" />
                            </svg>
                            <p className='ps-3 fs-16 text-azul'>Filtros de búsqueda</p>
                        </button>
                    }
                    {props.valoresFiltros.porMarcaVer && !props.valoresFiltros.porCategoriaVer && !props.valoresFiltros.porDescuentoVer && !props.valoresFiltros.porMonedaVer &&
                        <button className='btn-none d-flex justify-content-start align-items-center flex-row' onClick={() => props.backToMenu()}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289C12.0976 3.68342 12.0976 4.31658 11.7071 4.70711L5.41421 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H5.41421L11.7071 19.2929C12.0976 19.6834 12.0976 20.3166 11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L10.2929 3.29289Z" fill="#0C1561" />
                            </svg>
                            <p className='ps-3 fs-16 text-azul'>Filtrar por marca</p>
                        </button>
                    }
                    {props.valoresFiltros.porCategoriaVer && !props.valoresFiltros.porMarcaVer && !props.valoresFiltros.porDescuentoVer && !props.valoresFiltros.porMonedaVer &&
                        <button className='btn-none d-flex justify-content-start align-items-center flex-row' onClick={() => props.backToMenu()}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289C12.0976 3.68342 12.0976 4.31658 11.7071 4.70711L5.41421 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H5.41421L11.7071 19.2929C12.0976 19.6834 12.0976 20.3166 11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L10.2929 3.29289Z" fill="#0C1561" />
                            </svg>
                            <p className='ps-3 fs-16 text-azul'>Filtrar por categoria</p>
                        </button>
                    }
                    {props.valoresFiltros.porDescuentoVer && !props.valoresFiltros.porCategoriaVer && !props.valoresFiltros.porMarcaVer && !props.valoresFiltros.porMonedaVer &&
                        <button className='btn-none d-flex justify-content-start align-items-center flex-row' onClick={() => props.backToMenu()}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289C12.0976 3.68342 12.0976 4.31658 11.7071 4.70711L5.41421 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H5.41421L11.7071 19.2929C12.0976 19.6834 12.0976 20.3166 11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L10.2929 3.29289Z" fill="#0C1561" />
                            </svg>
                            <p className='ps-3 fs-16 text-azul'>Filtrar por descuento</p>
                        </button>
                    }
                    {props.valoresFiltros.porMonedaVer && !props.valoresFiltros.porDescuentoVer && !props.valoresFiltros.porCategoriaVer && !props.valoresFiltros.porMarcaVer &&
                        <button className='btn-none d-flex justify-content-start align-items-center flex-row' onClick={() => props.backToMenu()}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289C12.0976 3.68342 12.0976 4.31658 11.7071 4.70711L5.41421 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H5.41421L11.7071 19.2929C12.0976 19.6834 12.0976 20.3166 11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L10.2929 3.29289Z" fill="#0C1561" />
                            </svg>
                            <p className='ps-3 fs-16 text-azul'>Filtrar por monedas</p>
                        </button>
                    }
                </div>
                <div className="d-flex flex-column gap-3 p-3 mt-3">
                    {props.valoresFiltros.verTodosVer &&
                        <button type="button"
                            onClick={() => enable_filters("Ver todos")}
                            className={`btn btn-none d-flex flex-row justify-content-start align-items-center p-3 w-100 ${!props.valoresFiltros.verTodos ? 'box-white-without-shadow border borde-gris-claro' : 'shadow'}`} style={{ height: '51px' }}>
                            {props.valoresFiltros.verTodos ?
                                <img src={`${window.location.origin}/img/pantalla-beneficios/Checked.svg`} width="24px" height="24px" />
                                :
                                <img src={`${window.location.origin}/img/pantalla-beneficios/Unchecked.svg`} width="24px" height="24px" />
                            }
                            <p className='ms-2 fs-14 text-dark'>Ver todos</p>
                        </button>
                    }
                    {props.valoresFiltros.masCercanosVer &&
                        <button type="button"
                            onClick={() => enable_filters("Mas cercanos")}
                            className={`btn btn-none d-flex flex-row justify-content-start align-items-center p-3 w-100 ${!props.valoresFiltros.masCercanos ? 'box-white-without-shadow border borde-gris-claro' : 'shadow'}`} style={{ height: '51px' }}>
                            {props.valoresFiltros.masCercanos ?
                                <img src={`${window.location.origin}/img/pantalla-beneficios/Checked.svg`} width="24px" height="24px" />
                                :
                                <img src={`${window.location.origin}/img/pantalla-beneficios/Unchecked.svg`} width="24px" height="24px" />
                            }
                            <p className='ms-2 fs-14 text-dark'>Más cercanos</p>
                        </button>
                    }
                    {props.valoresFiltros.porMarcaVer &&
                        <button type="button"
                            onClick={() => enable_filters("Por marca")}
                            className={`btn btn-none d-flex flex-row justify-content-start align-items-center p-3 w-100 ${!props.valoresFiltros.porMarca ? 'box-white-without-shadow border borde-gris-claro' : 'shadow'}`} style={{ height: '51px' }} >
                            {props.valoresFiltros.porMarca ?
                                <img src={`${window.location.origin}/img/pantalla-beneficios/Checked.svg`} width="24px" height="24px" />
                                :
                                <img src={`${window.location.origin}/img/pantalla-beneficios/Unchecked.svg`} width="24px" height="24px" />
                            }
                            <p className='ms-2 fs-14 text-dark'>Por marca</p>
                        </button>
                    }
                    {props.expandirFiltros.filtroMarca &&
                        <CheckboxesFiltros list={props.checkboxLists.beneficiosMarcas} setValoresFiltros={props.setValoresFiltros} valoresFiltros={props.valoresFiltros} activarVerTodos={props.activarVerTodos} />
                    }
                    {props.valoresFiltros.porCategoriaVer &&
                        <button type="button"
                            onClick={() => enable_filters("Por categoria")}
                            className={`btn btn-none d-flex flex-row justify-content-start align-items-center p-3 w-100 ${!props.valoresFiltros.porCategoria ? 'box-white-without-shadow border borde-gris-claro' : 'shadow'}`} style={{ height: '51px' }}>
                            {props.valoresFiltros.porCategoria ?
                                <img src={`${window.location.origin}/img/pantalla-beneficios/Checked.svg`} width="24px" height="24px" />
                                :
                                <img src={`${window.location.origin}/img/pantalla-beneficios/Unchecked.svg`} width="24px" height="24px" />
                            }
                            <p className='ms-2 fs-14 text-dark'>Por categoría</p>
                        </button>
                    }
                    {props.expandirFiltros.filtroCategoria &&
                        <CheckboxesFiltros list={props.checkboxLists.beneficiosCategorias} setValoresFiltros={props.setValoresFiltros} valoresFiltros={props.valoresFiltros} activarVerTodos={props.activarVerTodos} />
                    }
                    {props.valoresFiltros.porDescuentoVer &&
                        <button type="button"
                            onClick={() => enable_filters("Por descuento")}
                            className={`btn btn-none d-flex flex-row justify-content-start align-items-center p-3 w-100 ${!props.valoresFiltros.porDescuento ? 'box-white-without-shadow border borde-gris-claro' : 'shadow'}`} style={{ height: '51px' }}>
                            {props.valoresFiltros.porDescuento ?
                                <img src={`${window.location.origin}/img/pantalla-beneficios/Checked.svg`} width="24px" height="24px" />
                                :
                                <img src={`${window.location.origin}/img/pantalla-beneficios/Unchecked.svg`} width="24px" height="24px" />
                            }
                            <p className='ms-2 fs-14 text-dark'>Por descuento</p>
                        </button>
                    }
                    {props.expandirFiltros.filtroDescuento &&
                        <CheckboxesFiltros list={props.checkboxLists.beneficiosDescuentos} setValoresFiltros={props.setValoresFiltros} valoresFiltros={props.valoresFiltros} activarVerTodos={props.activarVerTodos} />
                    }
                    {props.valoresFiltros.porMonedaVer && parseInt(contacto.codigo) >= 3 &&
                        <button type="button"
                            onClick={() => enable_filters("Por moneda")}
                            className={`btn btn-none d-flex flex-row justify-content-start align-items-center p-3 w-100 ${!props.valoresFiltros.porMoneda ? 'box-white-without-shadow border borde-gris-claro' : 'shadow'}`} style={{ height: '51px' }}>
                            {props.valoresFiltros.porMoneda ?
                                <img src={`${window.location.origin}/img/pantalla-beneficios/Checked.svg`} width="24px" height="24px" />
                                :
                                <img src={`${window.location.origin}/img/pantalla-beneficios/Unchecked.svg`} width="24px" height="24px" />
                            }
                            <p className='ms-2 fs-14 text-dark'>Por monedas</p>
                        </button>
                    }
                    {props.expandirFiltros.filtroMoneda &&
                        <CheckboxesFiltros list={props.checkboxLists.beneficiosMonedas} setValoresFiltros={props.setValoresFiltros} valoresFiltros={props.valoresFiltros} activarVerTodos={props.activarVerTodos} />
                    }
                </div>
                <div className='m-3' style={{position:'absolute',margin:'auto', right:'0', left:'0', bottom:'3px'}}>
                    <div className='d-flex justify-content-center align-items-center flex-column gap-3'>
                        <button type='submit' className='btn btn-azul w-100'>Aplicar</button>
                        <button type="button" className='btn-none' onClick={quitarFiltros}>Limpiar filtros aplicados</button>
                    </div>
                </div>
            </form>
            {props.children}
        </Fragment >
    )
}

export default BeneficiosFiltros;
